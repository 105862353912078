<template>
<b-overlay :show="showOverlay" rounded="sm">
      <div v-if="roles.length>0">
          <b-row>
          <b-col>
              <b-form-group
                label="Code utilisateur"
                description=""
              >
                <b-form-input
                  v-model="user.code"
                  placeholder=""
                  trim
                    :class="{'is-invalid': !required}"
                ></b-form-input>
              </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <b-form-group
                label="Poste"
                description=""
                
              >
                <b-form-input
                  v-model="user.poste"
                  placeholder="Ex: Consièrge"
                  trim
                  :class="{'is-invalid': !required}"
                ></b-form-input>
              </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-card class="border mx-3">
            
              <p class="w-100 text-muted">Cochez les rôles  de cet utilisateur</p>
              <div class="mt-1 pr-2 w-100 d-flex justify-content-around flex-wrap" style="height: 15em; overflow-y: auto; overflow-x: hidden;">
                    <label class="d-flex justify-content-around w-100 mr-2" v-for="(item, i) in roles" :key="item.idRole || i">
                      <div style="flex: 2" class="ml-2">
                          <h6 class="mt-0 font-weight-bold"><span> {{ item.libelleRole }} </span></h6>
                      </div>
                      <input  type="checkbox" v-model="item.selected" />
                  </label>
              </div>
          </b-card>
        </b-row>
        <b-row class="ml-2"><b-form-checkbox :disabled="submitted" v-model="user.isAdmin" >{{ user.isAdmin ?  'Retirer le Droit d\'administrateur' :'attribuer le droit d\'administrateur'  }}</b-form-checkbox></b-row>
        <hr>
        <div class="float-right">
          <b-button :disabled="submitted" @click.prevent="submitUserForm" variant="danger"><b-spinner class="ml-2" small v-if="submitted" style="margin-left: 5px;"></b-spinner> {{$t('data.cite_valider_cite')}}</b-button>
        </div>
      </div>
      <div v-else>
        <b-row>
          <div class="text-center">
            <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
            <span class="h4 d-inline-flex ml-2">aucun role défini</span>
            <br>
            <b-button size="lg" class="my-2" variant="outline-info" @click.prevent="createRole">définir un role</b-button>
            <p>{{$t('data.user_form_explication')}}</p>
          </div>
        </b-row>
      </div>
    </b-overlay>
</template>
<script>

const php = require("phpjs");
export default {
  name: "user-form",
  props: {
    action: {type: String, required: true, default: "add"},
    userToEdit:{ type:Object}
  },
  data: () => ({
    submitted:false,
    required:true,
   showOverlay:true,
    roles:[],
    user: {
      code: "",
      poste: "",
      isAdmin:false,
      roles:[],
    },


    
  }),
  components:{
    
  },
  computed:{
    selectedRoles: {
      get:function () {
        return this.roles.filter(elt => elt.selected).map(elt => elt.idRole)
      },
      set: function(){
        // return this.userToEdit.roles.filter(elt => elt.selected).map(elt => elt.idRole)
      }
        
    }
  },
  watch: {

  },
  methods: {

    submitUserForm(){
      if (!this.selectedRoles.length) {
                return App.error("Veuillez selectionner au moins un role")
      }
      if (php.empty(this.user.code)) {
          return App.error("Veuillez entrer le code Utilisateur")
      }
      this.user.roles=this.selectedRoles
      console.log('user', this.user)
      this.submitted = true
      if(this.action=="add"){
          axios.post('entreprises/utilisateurs', this.user).then(response => {
          this.$emit("userAdded", response.result);
            this.submitted = false
              App.alertSuccess(response.message)
        }).catch(error => {
            this.submitted = false
            App.notifyError(error.message)
        })
      }
      if(this.action=="edit"){
        axios.put('entreprises/utilisateurs/'+this.userToEdit.idUtilisateur, this.user).then(response => {
          this.$emit("userEdited", response.result);
            this.submitted = false
              App.alertSuccess(response.message)
        }).catch(error => {
            this.submitted = false
            App.notifyError(error.message)
        })
      }
      
    },

    async getRoles(){
        try {
              const roles = await axios.get("entreprises/roles").then(response => response.result);
              this.roles = roles.map(elt => ({...elt, selected: false}))
        } catch (error) {
            notif.error(error.message);
        }
        this.showOverlay=false;
    },

    
    
  


    
  },
  async mounted() {
    await this.getRoles();
    if(this.userToEdit){
      console.log('user to edit',this.userToEdit);
      this.user.code=this.userToEdit.utilisateur.code
      this.user.poste=this.userToEdit.poste
      this.user.isAdmin=this.userToEdit.isAdmin
      this.roles = this.roles.map(elt => ({...elt, selected: this.userToEdit.roles.map(elt => elt.idRole).includes(elt.idRole)}))
    }
  },

};
</script>
<style scoped>
  .example-form-control{
    height: auto;
    min-height: 35px;
    border: 1px solid #eaeaea;
    padding: 0 10px;
    background-color: #fff;
    font-size: 13px;
    border-radius: 4;
    margin-left:2em;

  }


      .leloader,
.leloader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.leloader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #e74c3c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
