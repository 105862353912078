<template>
    <div>
        
        <b-overlay :show="showOverlay" rounded="sm">
            <div class="card col-lg-12 col-md-12 col-sm-12" style="min-height: 20em">
                <b-button v-b-tooltip.top="$t('data.role_details_retour')" @click="() =>{ this.$router.push({name: 'users'}) }" class="card p-0 m-0 icon-list-table d-flex justify-content-center align-items-center"><i class="fa fa-arrow-left fa-2x" style="color:white"></i></b-button>
            <b-row>
                <b-col style="border-right: 1px solid gray">
                    <b-row style="max-height: 10em">
                            <b-col cols="9" style="max-height: 15em" class="my-auto">
                                <span class=" ml-1 w-100 font-weight-bold h5 text-uppercase">INFORMATIONS SUR L'utilisateur</span>
                            </b-col>
                            <b-col cols="3" class="d-flex justify-content-end pr-2"> 
                                <!--<b-img thumbnail fluid :src="userData.utilisateur.avatar" alt="logo entreprise" style="width: 5em;  height: 5em"></b-img>-->
                                <b-avatar :src="userData.utilisateur.avatar" size="5rem"></b-avatar>
                            </b-col>
                    </b-row>
                    <b-row>
                        <b-table-simple class="m-0 p-0" hover small responsive style="width: 90%">
                            <b-tbody>
                                <b-tr>
                                    <b-td >
                                        <span class="d-inline-block w-100 text-muted" style="width : 4em">Nom(s) et prenom(s)</span>
                                        <h6 class="font-weight-bold"><span class="text-uppercase" v-if="userData.utilisateur.nom !== ''&&userData.utilisateur.nom !== null">{{ userData.utilisateur.nom }}</span><span v-if="userData.utilisateur.prenom !==''&&userData.utilisateur.prenom !== null"> {{ userData.utilisateur.prenom }}</span></h6>    
                                    </b-td>
                                    <b-td >
                                        <span class="d-inline-block w-100" >Poste</span> 
                                        <h5><b-badge variant="primary">{{ userData.poste }}</b-badge> </h5>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td >
                                        <span class="d-inline-block w-100" >N° de Téléphone</span>
                                        <h4 class="font-weight-bold">{{ userData.utilisateur.tel }}</h4>     
                                    </b-td>
                                    <b-td>
                                        <span class="d-inline-block w-100 " >Poféssion</span> 
                                        <h4 v-if="userData.utilisateur.profil.profession !==null&&userData.utilisateur.profil.profession !==''" class="font-weight-bold">{{ userData.utilisateur.profil.profession }}</h4>
                                        <h4 v-else class="font-weight-bold">Agent immobilier</h4>    
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span class="d-inline-block w-100">N° pièce d'identité</span> 
                                        <span v-if="userData.utilisateur.profil.cni !==null&&userData.utilisateur.profil.cni !==''" class="h5" style="letter-spacing: 5px">{{ userData.utilisateur.profil.cni }}</span> 
                                        <span v-else class="h5" style="letter-spacing: 5px">non défini</span>   
                                    </b-td>
                                    <b-td>
                                        <span class="d-inline-block w-100" >Adresse Mail</span>
                                        <span style="color: blue" class="w-100 ">{{ userData.utilisateur.email }}</span>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                        <hr>
                        <div>
                            <b-col  class="ml-4 d-flex justify-content-end">
                                <b-form-group label="" >
                                    <a  href="" v-b-tooltip.bottom="$t('data.detail_habitant_modifier')" @click.prevent="goToProfile">
                                        <i class="fas fa-edit" style="cursor:pointer; font-size: 24px"></i></a>
                                </b-form-group>
                            </b-col>
                        </div>
                               
                    </b-row>
                </b-col>
                <b-col>
                    <b-row style="max-height: 10em">
                            <b-col cols="9" style="max-height: 15em" class="my-auto">
                                <span class=" ml-1 w-100 font-weight-bold h5">INFORMATIONS SUR L'ENTREPRISE</span>
                            </b-col>
                            <b-col cols="3" class="d-flex justify-content-end pr-2"> 
                                <b-avatar :src="entrepriseData.logo" size="5rem"></b-avatar>
                                <!--<b-img thumbnail fluid src="@/assets/img/logo_flysoft.png" alt="logo entreprise" style="width: 5em;  height: 3em"></b-img>-->
                            </b-col>
                    </b-row>
                    <b-table-simple class="m-0 p-0" hover small responsive style="width: 90%">
                        <b-tbody>
                            <b-tr>
                                <b-td >
                                    <span class="d-inline-block w-100 text-muted" style="width : 4em">Nom</span>
                                    <h6 class="font-weight-bold text-uppercase">{{ entrepriseData.nom }}</h6>      
                                </b-td>
                                <b-td >
                                    <span class="d-inline-block w-100" >Raison Sociale</span> 
                                    <h5 class="text-uppercase"><b-badge class=" font-weight-bold" variant="info">{{ entrepriseData.raisonSociale }}</b-badge> </h5>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td >
                                    <span class="d-inline-block w-100" >N° de Téléphone</span>
                                    <h4 class="font-weight-bold">{{ entrepriseData.tel }}</h4>      
                                </b-td>
                                <b-td>
                                    <span class="d-inline-block w-100 " >Siège Social</span> 
                                    <h4 v-if="entrepriseData.siegeSocial !==''" class="font-weight-bold text-uppercase">{{ entrepriseData.siegeSocial }}</h4> 
                                    <h4 v-else class="font-weight-bold text-uppercase">Non défini</h4>    
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <span class="d-inline-block w-100">Registre de Commerce</span> 
                                    <b-badge><h5 class="font-weight-bold">{{ entrepriseData.registreCommerce }}</h5></b-badge>     
                                </b-td>
                                <b-td>
                                    <span class="d-inline-block w-100" >Adresse Mail</span>
                                    <span style="color: blue" class="w-100 ">{{ entrepriseData.email }}</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
        </b-overlay>
        <b-row>
            <b-col lg="4" md="5" sm="12">
                <div class="card overflow-hidden px-2 py-3">
                    <div class="d-flex justify-content-between">
                        <h4 class="m-1 font-weight-bold">liste des roles</h4>
                        <b-button @click.prevent="addRoleToUser" style="background: #f5365c; border-color: #f5365c"><i class="ik ik-plus" style="width: 100%"></i> </b-button>
                    </div>
                    <hr>
                    <b-overlay :show="showOverlayTable" rounded="sm">
                        <b-table  sticky-header="90%" striped hover responsive :items="roles" :fields="[
                                { key: 'index', label: 'N°', sortable: true  },
                                { key: 'libelle', label: 'Libellé', sortable: true },
                                { key: 'action', label: '' },
                            ]" style="min-height: 10em " class="table table-bordered"> 
                            <template #cell(index)="data">{{ ++data.index }}</template>
                            <template #cell(libelle)="data">{{ data.item.libelleRole }}</template>
                            <template #cell(action)="row">
                                <div style = "display: flex">
                                    <b-button size="sm" variant ="light" @click.prevent="deleteRoleUser(row.item)" class="ml-2"><i style="width: 100%" class="ik ik-delete"></i></b-button>
                                </div>  
                            </template>
                        </b-table>
                    </b-overlay>
                </div>
            </b-col>
            <b-col lg="8" md="7" sm="12" class="pb-0">
                <div class="card overflow-hidden px-2 py-3">
                    <div class="d-flex justify-content-between">
                        <h4 class="m-1 font-weight-bold">liste des Privilèges</h4>
                        <b-button style="background: #f5365c; border-color: #f5365c"><i class="ik ik-plus" style="width: 100%"></i> </b-button>
                    </div>
                    <hr>
                    <b-overlay :show="showOverlayTable" rounded="sm">
                        <b-table  sticky-header="90%" striped hover responsive :items="permissions" :fields="[
                                { key: 'index', label: 'N°', sortable: true  },
                                { key: 'codePrivilege', label: 'Code', sortable: true },
                                { key: 'libelle', label: 'Libellé', sortable: true },
                                { key: 'description', label: 'Description', sortable: true },
                                { key: 'action', label: '' },
                            ]" style="min-height: 10em " class="table table-bordered"> 
                            <template #cell(index)="data">{{ ++data.index }}</template>
                            <template #cell(codePrivilege)="data"><h5><b-badge>{{ data.item.codePrivilege }}</b-badge></h5></template>
                            <template #cell(libelle)="data">{{ data.item.libellePrivilege }}</template>
                            <template #cell(description)="data">{{ data.item.description }}</template>
                            <template #cell(action)="row">
                                <div style = "display: flex">
                                    <b-button size="sm" variant ="light" @click.prevent="deletePrivilegeUser(row.item)" class="ml-2"><i style="width: 100%" class="ik ik-delete"></i></b-button>
                                </div>  
                            </template>
                        </b-table>
                    </b-overlay>
                </div>
            </b-col>
        </b-row>
        <!--MODAL D'AFFECTATION DE ROLE A UN UTILISATEUR -->
        <b-modal id="roleToUserForm" size="sm" title="Affectation de role" hide-footer no-close-on-backdrop>
            <div>
                <b-overlay :show="showOverlay" rounded="sm">
                    <div v-if="rolesEntreprise.length>0">
                        <b-row>
                        <b-card class="border mx-3">
                            <p class="w-100 text-muted">Cochez les rôles  de cet utilisateur</p>
                            <div class="mt-1 pr-2 w-100 d-flex justify-content-around flex-wrap" style="height: 20em; overflow-y: auto; overflow-x: hidden;">
                                    <label class="d-flex justify-content-around w-100 mr-2" v-for="(item, i) in rolesEntreprise" :key="item.idRole || i">
                                    <div style="flex: 2" class="ml-2">
                                        <h6 class="mt-0 font-weight-bold"><span> {{ item.libelleRole }} </span></h6>
                                    </div>
                                    <input  type="checkbox" v-model="item.selected" />
                                </label>
                            </div>
                        </b-card>
                        </b-row>
                        <b-row class="ml-2"><b-form-checkbox :disabled="submitted" v-model="user.isAdmin" >{{ user.isAdmin ?  'Retirer le Droit d\'administrateur' :'attribuer le droit d\'administrateur'  }}</b-form-checkbox></b-row>
                        <hr>
                        <div class="float-right">
                            <b-button :disabled="submitted" @click.prevent="submitUserForm" variant="danger"><b-spinner class="ml-2" small v-if="submitted" style="margin-left: 5px;"></b-spinner> {{$t('data.cite_valider_cite')}}</b-button>
                        </div>
                    </div>
                    <div v-else>
                        <b-row>
                        <div class="text-center">
                            <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
                            <span class="h4 d-inline-flex ml-2">aucun role défini</span>
                            <br>
                            <b-button size="lg" class="my-2" variant="outline-info" @click.prevent="createRole">définir un role</b-button>
                            <p>{{$t('data.user_form_explication')}}</p>
                        </div>
                        </b-row>
                    </div>
                </b-overlay>
            </div>
</b-modal>
    </div>
</template>
<script>
import UserForm from '@/views/gestion-utilisateurs/users/UserForm.vue'
export default {
    name:'user-details',
    computed:{
        selectedRoles() {
            return this.rolesEntreprise.filter(elt => elt.selected).map(elt => elt.idRole)   
        }
    },
    components:{
        UserForm
    },
    data: () => ({
        user:{
            poste:'',
            roles:[],
            isAdmin: false,
            isProprietaire:false
        },
        rolesEntreprise: [],
        showOverlayTable: true,
        showOverlayRoles:true,
        showOverlay:true,
        submitted:false,
        userData:{},
        users:[],
        roles:[
            { 
                id: 1,
                libelleRole: 'admin'
            },
            { 
                id: 2,
                libelleRole: 'user'
            },
            { 
                id: 3,
                libelleRole: 'secretaire'
            },
            { 
                id: 4,
                libelleRole: 'caissier'
            },
            { 
                id: 5,
                libelleRole: 'magasinier'
            },
            { 
                id: 6,
                libelleRole: 'bailleur'
            },
            { 
                id: 7,
                libelleRole: 'releveur'
            },
            { 
                id: 3,
                libelleRole: 'secretaire'
            },
        ],
        permissions:[
            {
                numero : 1,
                codePrivilege:'list_user',
                libellePrivilege:" liste des utilisateurs",
                description:'Droit de voir la liste des Utilisateurs',
            },
            {
                numero : 2,
                codePrivilege:'add_user',
                libellePrivilege:" ajouter un utilisateur",
                description:'Droit de pouvoir ajouter un utilisateur',
            },
            {
                numero : 3,
                codePrivilege:'edit_user',
                libellePrivilege:" mettre à jour information d'un utilisateur",
                description:'Droit de pouvoir modifier les informations d\'un utilisateur',
            },
            {
                numero : 4,
                codePrivilege:'delete_user',
                libellePrivilege:" supprimer un utilisateur",
                description:'Droit de pouvoir supprimer un utilisateur',
            },
            {
                numero : 5,
                codePrivilege:'list_roles',
                libellePrivilege:" liste des roles",
                description:'Droit de pouvoir voir la liste des roles',
            },
            {
                numero : 6,
                codePrivilege:'add_role',
                libellePrivilege:" ajouter un role",
                description:'Droit de pouvoir ajouter un role',
            },
            {
                numero : 7,
                codePrivilege:'edit_role',
                libellePrivilege:" mettre à jour un role",
                description:'Droit de pouvoir modifier un role',
            },
            {
                numero : 8,
                codePrivilege:'delete_role',
                libellePrivilege:"supprimer un role",
                description:'Droit de pouvoir supprimer un role',
            }
        ],
      }),
    methods: {
        goToProfile(){
            this.$router.push({ name: 'profil'})
        },
        submitUserForm(){
            if (!this.selectedRoles.length) {
                        return App.error("Veuillez selectionner au moins un role")
                }
    
            this.user.roles=this.selectedRoles
            console.log('user', this.user)
            this.submitted = true

            axios.post('entreprises/utilisateurs', this.user).then(response => {
            this.$emit("userAdded", response.result);
                this.submitted = false
                App.alertSuccess(response.message)
            }).catch(error => {
                this.submitted = false
                App.notifyError(error.message)
            })
        },
        addRoleToUser(){
            this.$bvModal.show('roleToUserForm')
            this.getRoles();
        },
        deleteRoleUser(role){
            console.log('role', role);
        },
        deletePrivilegeUser(privilege){
            console.log('privilège to delete', privilege);
        },
        async getRoles(){
            try {
                const roles = await axios.get("entreprises/roles").then(response => response.result);
                this.roles = roles.map(elt => ({...elt, selected: false}))
            } catch (error) {
                notif.error(error.message);
            }
             this.showOverlay=false;
        },
        async getData() {
             this.showOverlay = true
              let href = window.location.href.split('/'), idUser = href[href.length - 1]
            try {
                 this.userData = await axios.get("entreprises/utilisateurs/"+idUser).then(response => response.result);
                 
                 this.roles=this.userData.roles
                 /*if(this.roles.length > 0){
                        for(let i=0; i<this.roles.length; i++){
                            console.log('entrée des roles', this.roles[i] );
                            for(let j=0; j<this.roles[i].privileges.length; j++){
                                this.permissions.push(this.roles[i].privileges[j]);
                            }
                        }
                    }*/
                 /*for(let i=0; i<=this.roles.length; i++){
                     this.privileges= this.roles.privileges[i].map((item) => item)
                 }*/
            } catch (error) {
                App.notifyError(error.message)
            }
     
            try {
                 this.entrepriseData = await axios.get("entreprises").then(response => response.result);
            } catch (error) {
                App.notifyError(error.message)
            }
          
            this.showOverlay=false;
            setTimeout(() => {
                this.showOverlayTable= false;
            }, 2000)
         },
         createRole(){
             this.$router.push({name: 'roles'})
         }
      },
      beforeMount(){
          this.getData();
      },
      async mounted(){
         //await this.getData();
        /*setTimeout(() => {
            this.showOverlayTable= false;
        }, 2000)*/

    },
}
</script>
<style scoped>
    .icon-list-table{
        width: 3em;
         height: 2em; 
         background: red;
         position: absolute;
         top:-5%;
    }
    .text-list{
        position: absolute;
        top: 5px;
        left: 8%;
    }
    .actions-buttons-group{
        position: absolute;
        top: 5em;
        left: 8%
    }

</style>